


























import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'VaimoButtons',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    },
    block: {
      type: Boolean,
      default: false
    },
    positionFilter: {
      type: String,
      required: false,
      default: 'Default'
    },
    forceShowSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    countdownEmail: {
      type: Boolean,
      required: false,
      default: false
    },
    calendarData: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const { getButtonDataFunction } = useDeclination(props.sectionData);

    const buttons = computed(() => {
      if (props.sectionData?.button) {
        const btn = getButtonDataFunction(props.sectionData.button);
        return btn?.isButtonVisible ? [btn] : [];
      }

      const buttons =
        props.sectionData?.buttonCollection?.items?.reduce((acc, item) => {
          if (item && item.__typename?.toLowerCase() === 'button') {
            const buttonData = getButtonDataFunction(item);
            if (buttonData.isCalendarButton && props.calendarData.isExpired) {
              buttonData.isButtonVisible = false;
            }
            if (buttonData && buttonData.isButtonVisible) {
              acc.push(buttonData);
            }
          }
          return acc;
        }, []) || [];

      return buttons.filter((btn) => btn.position === props.positionFilter);
    });

    return {
      buttons
    };
  }
});
