<template>
  <div class="vaimo-collected-product">
    <div class="vaimo-collected-product__aside">
      <slot name="image" v-bind="{ image, title }">
        <VaimoBanner
          :src="image"
          :alt="title"
          :link="isSampleProduct ? null : link"
          :max-optimized-width="190"
          :quality="100"
          ratio="4:5"
          source="magento"
          @click.native="toggleSidebar"
        />
      </slot>
    </div>
    <div class="vaimo-collected-product__main">
      <div class="vaimo-collected-product__body">
        <slot name="title" v-bind="{ title }">
          <div class="vaimo-collected-product__title-wraper">
            <component
              :is="componentIs"
              :link="isSampleProduct ? null : link || ''"
              class="vaimo-collected-product__title"
              :class="{
                'sample-product': isSampleProduct,
                'virtual-product': isVirtualProduct
              }"
              @click.native="toggleSidebar"
              @keypress.native.enter="toggleSidebar"
            >
              {{ title }}
            </component>
          </div>
        </slot>

        <div class="vaimo-collected-product__details">
          <slot name="details"></slot>
        </div>

        <div class="vaimo-collected-product__engraving">
          <slot name="engraving"></slot>
        </div>

        <div class="vaimo-collected-product__actions">
          <slot name="actions"></slot>
        </div>

        <slot name="more-actions" v-bind="{ actionsHandler }">
          <div v-if="hasMoreActions">
            <SfButton
              aria-label="More actions"
              class="vaimo-button--pure vaimo-collected-product__more-actions smartphone-only"
              @click="actionsHandler"
              @keypress.enter="actionsHandler"
            >
              <SfIcon icon="more" size="18px" />
            </SfButton>
          </div>
        </slot>
      </div>
      <div
        class="vaimo-collected-product__footer"
        :class="{ 'is-virtual-product': isVirtualProduct }"
      >
        <slot v-if="!isVirtualProduct" name="input">
          <SfQuantitySelector
            :qty="quantity"
            :min="minQty"
            :max="maxQty"
            class="vaimo-collected-product__quantity-selector"
            @input="$emit('input', $event)"
          />
        </slot>
        <slot name="price" v-bind="{ specialPrice, regularPrice }">
          <SfPrice
            v-if="regularPrice"
            :regular="regularPrice"
            class="vaimo-collected-product__price"
          />
        </slot>
      </div>

      <slot name="remove" v-bind="{ removeHandler }">
        <div
          v-if="hasRemove"
          role="button"
          aria-roledescription="Button that removes the product form the cart"
          aria-label="Remove Product"
          title="Remove Product"
          tabindex="0"
          class="vaimo-collected-product__remove"
          @click="removeHandler"
          @keypress.enter="removeHandler"
        >
          &times;
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import {
  SfButton,
  SfCircleIcon,
  SfIcon,
  SfImage,
  SfLink,
  SfPrice,
  SfProperty,
  SfQuantitySelector
} from '@storefront-ui/vue';
import VaimoBanner from 'atoms/VaimoBanner.vue';

import { useUiState } from '~/composables/useUiState';

const { isCartSidebarOpen, toggleCartSidebar } = useUiState();

export default {
  name: 'VaimoCollectedProduct',
  components: {
    SfButton,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfPrice,
    SfQuantitySelector,
    SfLink,
    SfProperty,
    VaimoBanner
  },
  model: {
    prop: 'qty'
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    image: {
      type: String,
      default: ''
    },
    imageWidth: {
      type: [Number, String],
      default: 140
    },
    imageHeight: {
      type: [Number, String],
      default: 200
    },
    title: {
      type: String,
      default: ''
    },
    regularPrice: {
      type: [Number, String],
      default: null
    },
    specialPrice: {
      type: [Number, String],
      default: null
    },
    qty: {
      type: [Number, String],
      default: 1
    },
    minQty: {
      type: Number,
      default: null
    },
    maxQty: {
      type: Number,
      default: null
    },
    link: {
      type: [String, Object],
      default: null
    },
    hasRemove: {
      type: Boolean,
      default: true
    },
    hasMoreActions: {
      type: Boolean,
      default: true
    },
    additionalData: {
      type: String,
      required: false,
      default: '[]'
    },
    isVirtualProduct: Boolean
  },
  computed: {
    componentIs() {
      return this.link ? 'SfLink' : 'div';
    },
    quantity() {
      return typeof this.qty === 'string' ? Number(this.qty) : this.qty;
    },
    isSampleProduct() {
      let parsedData = this.additionalData;
      try {
        parsedData = JSON.parse(this.additionalData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

      return parsedData?.is_sample || parsedData?.is_checkout_sample || false;
    }
  },
  methods: {
    removeHandler() {
      this.$emit('click:remove');
    },
    actionsHandler() {
      this.$emit('click:actions');
    },
    toggleSidebar() {
      if (this.isSampleProduct) {
        return;
      }
      if (isCartSidebarOpen.value) {
        toggleCartSidebar(null);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vaimo-collected-product {
  border: none;
  position: relative;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 20px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &__main {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    order: 2;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.is-virtual-product {
      align-self: flex-end;
    }
  }

  &__title {
    font-family: var(--font-family--secondary);
    font-weight: 400;
    font-size: 14px;
    padding-right: 20px;
    max-height: 3em;
    overflow: hidden;
    text-decoration: none;
  }

  &__title-wraper {
    padding-right: 20px;
    .sample-product,
    .virtual-product {
      cursor: default;
    }
  }

  &__model {
    font-family: var(--font-family--primary);
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
  }

  &__remove {
    position: absolute;
    right: -5px;
    top: 0;
    font-family: var(--font-family--primary);
    box-sizing: content-box;
    font-size: 36px;
    font-weight: 100;
    line-height: 5px;
    height: 16px;
    padding: 5px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.1s;
    outline: none;
    &:hover {
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 3px solid black;
    }
  }

  ::v-deep {
    .sf-price__regular {
      font-family: var(--font-family--primary);
      font-weight: 400;
      font-size: 14px;
      padding-top: 4px;
      margin-left: auto;
    }

    .sf-quantity-selector {
      height: 25px;
      width: 90px;
      &__input {
        height: 25px;
        min-height: 25px;
      }
    }

    .sf-input input {
      outline: none;
      border: 1px solid $gris_nacre;
      padding: 2px 5px;
      text-align: center;
      width: 30px;
      margin: 0 auto;
    }
  }
}
</style>
